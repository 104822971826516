import React, { useState, useEffect } from "react";
import Base from "./Base";
import { Select } from "antd";
import { API_BASE_URL, BASE_SUPERID } from "./Config";
import Swal from "sweetalert2";
import { Table, Pagination } from "react-bootstrap";
import "./popup.css";
import "./loading.css";

import {
  CCarousel,
  CCarouselItem,
  CCarouselCaption,
  CImage,
} from "@coreui/react";

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [storedData, setStoredData] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [announceloading, setAnnounceLoading] = useState(true);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAnnouncePopup, setShowAnnouncePopup] = useState(false);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsData, setStudentsData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editData, setEditData] = useState([]);
  const itemsPerPage = 8;

  const [pickupChecked, setPickupChecked] = useState(false);
  const [visitChecked, setVisitChecked] = useState(false);
  const [othersChecked, setOthersChecked] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [announcments, setAnnouncments] = useState([]);
  const [advertisementImages, setAdvertisementImages] = useState([]);
  const [selectedAnnounceData, setSelectedAnnounceData] = useState("");

  useEffect(() => {
    const userDataSession = sessionStorage.getItem("userDataSession");
    if (userDataSession) {
      setStoredData(JSON.parse(userDataSession));
    }
  }, []);

  useEffect(() => {
    const storedStudentData = sessionStorage.getItem("studentsData");

    if (storedStudentData) {
      const parsedData = JSON.parse(storedStudentData);
      setStudentsData(parsedData);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_URL}VisitsPickups/getvisitorpickup?mobileno=${storedData.Mobile}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData.ResultData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (storedData.Mobile) {
      fetchData();
    }
  }, [storedData.Mobile]);

  useEffect(() => {
    const fetchData = async () => {
      setAnnounceLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_URL}/Announcements/getAnnouncements?superid=${BASE_SUPERID}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setAnnouncments(jsonData.ResultData);
      } catch (error) {
        setError(error.message);
      } finally {
        setAnnounceLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}ImgAdvertisement/GetimagesAdvPaths?superid=${BASE_SUPERID}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        const imageNames = jsonData.ResultData; // Assuming this is an array of image names

        // Create an array to store promises for image loading
        const imagePromises = imageNames.map(async (imagename) => {
          try {
            const imageUrl = `${API_BASE_URL}ImgAdvertisement/images/${imagename.imagename}`;
            const response = await fetch(imageUrl);
            if (!response.ok) {
              throw new Error("Failed to fetch image");
            }
            const blob = await response.blob();
            // Create an object containing the image URL and the blob data
            return { url: imageUrl, blob };
          } catch (error) {
            console.error("Error fetching image:", error);
            return null; // Return null for failed images
          }
        });

        // Wait for all image promises to resolve
        const images = await Promise.all(imagePromises);

        // Filter out any null values (failed image fetches)
        const validImages = images.filter((image) => image !== null);

        // Save the array of valid images in the state
        setAdvertisementImages(validImages);

        console.log("Advertisement images loaded successfully:", validImages);
      } catch (error) {
        setError(error.message);
      } finally {
        console.log("Fetch operation completed");
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredData = data.filter((item) => {
    const searchValue = searchInput.toLowerCase(); // Convert search value to lowercase
    return (
      // Check if any of the properties contain the search value
      (item.Reason && item.Reason.toLowerCase().includes(searchValue)) ||
      (item.VisitorName &&
        item.VisitorName.toLowerCase().includes(searchValue)) ||
      // Filter by RequestType
      (item.RequestType === 1 && searchValue === "visit") ||
      (item.RequestType === 2 && searchValue === "pickup")
    );
  });

  const handleEditButtonClick = (item) => {
    setEditData(item);
    setShowEditPopup(true);
  };

  const handleAnnounceClick = (item) => {
    setSelectedAnnounceData(item);
    setShowAnnouncePopup(true);
  };

  const handleAnnounceClosePopup = () => {
    setShowAnnouncePopup(false);
  };

  const handleEditClosePopup = () => {
    setShowEditPopup(false);
  };

  const handleAddButtonClick = (item) => {
    setShowAddPopup(true);
  };

  const handleAddClosePopup = () => {
    setShowAddPopup(false);
  };

  const handleChangeStudent = (selectedItems) => {
    setSelectedItems(selectedItems);
    // console.log(selectedItems, 'selected students ids');
  };

  const filteredOptions = studentsData
    .map((student) => ({
      stuID: student.regid,
      name: student.name,
    }))
    .filter((item) => !selectedItems.includes(item));

  const handleSave = () => {};

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCheckboxChange = (checkbox) => {
    if (checkbox === "pickup") {
      setPickupChecked(true);
      setVisitChecked(false);
      setOthersChecked(false);
      setFormData((prevState) => ({ ...prevState, requesttype: 2 }));
    } else if (checkbox === "visit") {
      setPickupChecked(false);
      setVisitChecked(true);
      setOthersChecked(false);
      setFormData((prevState) => ({ ...prevState, requesttype: 1 }));
    } else {
      setPickupChecked(false);
      setVisitChecked(false);
      setOthersChecked(true);
      setFormData((prevState) => ({ ...prevState, requesttype: 2 }));
    }
  };

  const [formData, setFormData] = useState({
    regid: [],
    startdatetime: "",
    enddatetime: "",
    visitorname: "",
    reason: "",
    requesttype: 0, // Default value, to be updated based on checkbox selection
    mobileno: storedData.Mobile,
    guardiandetailsid: 0, // Assuming this value is static
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitBtnLoading(true);
    // Access storedData.Mobile directly from the formData
    formData.mobileno = storedData?.Mobile;
    formData.regid = selectedItems;
    const startDate = new Date(formData.startdatetime);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const hours = String(startDate.getHours()).padStart(2, "0");
    const minutes = String(startDate.getMinutes()).padStart(2, "0");
    const seconds = String(startDate.getSeconds()).padStart(2, "0");

    formData.startdatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    if (!isNaN(new Date(formData.enddatetime).getTime())) {
      // If it's a valid date, format it
      const endDate = new Date(formData.enddatetime);
      const year = endDate.getFullYear();
      const month = String(endDate.getMonth() + 1).padStart(2, "0");
      const day = String(endDate.getDate()).padStart(2, "0");
      const hours = String(endDate.getHours()).padStart(2, "0");
      const minutes = String(endDate.getMinutes()).padStart(2, "0");
      const seconds = String(endDate.getSeconds()).padStart(2, "0");

      formData.enddatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else {
      // If it's not a valid date, set it to null or an empty string
      formData.enddatetime = ""; // or null, depending on your backend's requirements
    }

    console.log(formData, "before fetching apiiii");

    fetch(`${API_BASE_URL}VisitsPickups/CreateVisitorPickups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          console.log("Success!");
          setSubmitBtnLoading(false);
          // Show success message
          Swal.fire({
            title: "Success",
            text: "Request Sbmitted successfully.",
            icon: "success",
          }).then((result) => {
            // Reload the page after the user acknowledges the success message
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        } else {
          setSubmitBtnLoading(false);
          // Show error message
          Swal.fire({
            title: "Error",
            text: "Failed to Request Sbmit.",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        setSubmitBtnLoading(false);
        console.error("Error:", error);
        // Handle errors
      }); // Handle response data
  };

  const getStatusInfo = (statusId) => {
    switch (statusId) {
      case 1:
        return { name: "Pending", color: "orange" };
      case 2:
        return { name: "Success", color: "green" };
      case 3:
        return { name: "Rejected", color: "red" };
      case 4:
        return { name: "Expired", color: "gray" };
      default:
        return { name: "Unknown", color: "black" }; // Default to black color for unknown statuses
    }
  };

  const removeAmPm = (timeString) => {
    return timeString.replace(/\s[AP]M$/, ""); // Replace "AM" or "PM" followed by a space at the end of the string with an empty string
  };

  const removeAmPmAndTime = (dateTimeString) => {
    return dateTimeString.replace(/\s[AP]M$/, "").split(" ")[0]; // Remove "AM" or "PM" suffix and then split by space to get only date
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const colors = ["blue", "red", "green", "orange", "pink", "violet"];

  // Function to generate a random color from the array
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  return (
    <Base>
      <div className="d-flex flex-column flex-md-row">
        <div className="container col-md-8">
          <div className="row">
            <div className="col-md-9">
              <h6 className="mt-4">
                Requests <i className="fa-solid fa-code-pull-request"></i>
              </h6>
            </div>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control my-3 float-end"
                placeholder="Search..."
                value={searchInput}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="border rounded shadow">
            <Table hover responsive className="custom-table">
              <thead>
                <tr className="text-center">
                  <th>S.No</th>
                  <th>Apply Date</th>
                  <th>Type</th>
                  <th>Visitor Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Reason</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="12" className="text-center fw-semibold fs-5">
                      <h5 className="text-primary">
                        Loading
                        <span className="spinner-border spinner-border-sm text-warning ms-1"></span>
                      </h5>
                    </td>
                  </tr>
                ) : currentItems.length === 0 ? (
                  <tr>
                    <td colSpan="12" className="text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <th className="text-center">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </th>
                      <td className="text-center">
                        {removeAmPmAndTime(item.CreatedOn)}
                      </td>
                      <td className="text-center">
                        {item.RequestType == 1 ? "Visit" : "Pickup"}
                      </td>
                      <td>{item.VisitorName}</td>
                      <td className="text-center">
                        {removeAmPm(item.StartDateTime)}
                      </td>
                      <td className="text-center">
                        {item.EndDateTime === "01-01-1900 00:00 AM"
                          ? "N/A"
                          : removeAmPm(item.EndDateTime)}
                      </td>
                      <td>{item.Reason}</td>
                      <td
                        className="fw-semibold text-center"
                        style={{ color: getStatusInfo(item.Status).color }}
                      >
                        {getStatusInfo(item.Status).name}
                      </td>
                      <td className="text-center">
                        <div className="d-flex">
                          <button
                            className="btn"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditButtonClick(item)}
                            title="Edit"
                          >
                            <i className="fa-regular fa-pen-to-square text-primary fs-5"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
              <Pagination>
                {Array.from({
                  length: Math.ceil(filteredData.length / itemsPerPage),
                }).map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4 mt-md-0 ">
          <div className="row">
            <div className="col-md-9"></div>
            <div className="col-md-3 mt-4 mb-2">
              <button
                className="btn btn-success border"
                onClick={handleAddButtonClick}
              >
                +
              </button>
            </div>
          </div>
          <div className="card shadow noti-card">
            <h5 className="card-title m-2">Recent Announcements</h5>
            <div
              className="card-body"
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              <div className="row">
                {announceloading ? (
                  <h5 className="text-primary text-center">
                    Loading
                    <span className="spinner-border spinner-border-sm text-warning ms-1"></span>
                  </h5>
                ) : announcments.length === 0 ? (
                  <p className="text-center">No data available</p>
                ) : (
                  announcments.map((item, index) => {
                    const endDate = new Date(item.enddatetime);
                    const formattedEndDate =
                      endDate.toLocaleDateString("en-GB");

                    const startDate = new Date(item.enddatetime);
                    const formattedStartDate =
                      startDate.toLocaleDateString("en-GB");
                    return (
                      <div
                        className="col-md-6 mb-3 mb-md-0"
                        onClick={() => handleAnnounceClick(item)}
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        <div
                          className="rounded d-flex flex-column justify-content-between"
                          style={{
                            borderLeft: `solid ${getRandomColor()} 5px`,
                            boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.1)",
                            height: "100%",
                          }}
                        >
                          <div className="m-2">
                            <p className="text-capitalize">{item.name}</p>
                            <p className="fw-semibold">
                              {formattedStartDate} - {formattedEndDate}
                            </p>
                          </div>
                          {/* Add additional content here if needed */}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>

          <div className="card shadow m-1">
            {advertisementImages.length <= 0 ? (
              <h5 className="text-primary text-center">
                Loading
                <span className="spinner-border spinner-border-sm text-warning ms-1"></span>
              </h5>
            ) : (
              <CCarousel controls indicators transition="crossfade">
                {advertisementImages.map((image, index) => (
                  <CCarouselItem key={index}>
                    <CImage
                      className="d-block w-100 carousel-image"
                      src={image.url}
                      alt={`slide ${index + 1}`}
                    />
                  </CCarouselItem>
                ))}
              </CCarousel>
            )}
          </div>
        </div>
      </div>

      {showEditPopup && (
        <div className="popup ">
          <div className="popup-content card">
            <div className="d-flex">
              <h3 className="text-start">Edit Request</h3>
              <p
                style={{ cursor: "pointer" }}
                onClick={handleEditClosePopup}
                className="text-dark d-flex ms-auto"
              >
                <i className="fa-solid fa-xmark"></i>
              </p>
            </div>
            <hr></hr>
            <form>
              <div className="d-flex flex-column col-12">
                <div className="d-flex mt-4">
                  <div className="col-6 me-1">
                    <label className="form-label">Student:</label>
                    <Select
                      mode="multiple"
                      placeholder="Inserted are removed"
                      value={editData.RegID}
                      onChange={handleChangeStudent}
                      style={{ width: "100%", height: "37px" }}
                    >
                      {filteredOptions.map((item) => (
                        <Select.Option key={item.stuID} value={item.stuID}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-6 ms-1">
                    <label className="form-label">Visitor Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Visitor name"
                      value={editData.VisitorName}
                    />
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="col-6 me-1">
                    <label className="form-label">Start Date:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={editData.StartDateTime}
                    />
                  </div>
                  <div className="col-6 ms-1">
                    <label className="form-label">End Date:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={editData.EndDateTime}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <label className="form-label">Reason</label>
                  <textarea
                    placeholder="Enter Reason"
                    className="form-control"
                    value={editData.Reason}
                  ></textarea>
                </div>
                <div className="d-flex ms-auto mt-4">
                  <button
                    className="btn btn-secondary me-2"
                    onClick={handleEditClosePopup}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={handleSave}>
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {showAnnouncePopup && (
        <div className="popup">
          <div className="popup-content card shadow">
            <div className="d-flex justify-content-between align-items-center p-3 bg-primary text-light">
              <h6 className="m-0">{selectedAnnounceData.name}</h6>
              <button
                className="btn-close btn-close-white"
                onClick={handleAnnounceClosePopup}
              ></button>
            </div>
            <div className="p-3">
              <h6 className="fw-bold">Description:</h6>
              <p className="m-0">{selectedAnnounceData.description}</p>
            </div>
          </div>
        </div>
      )}

      {showAddPopup && (
        <div className="popup ">
          <div className="popup-content card">
            <div className="d-flex">
              <h3 className="text-start">Add Request</h3>
              <p
                style={{ cursor: "pointer" }}
                onClick={handleAddClosePopup}
                className="text-dark d-flex ms-auto"
              >
                <i className="fa-solid fa-xmark"></i>
              </p>
            </div>
            <hr></hr>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-2">
                <label className="form-label">Student</label>
                <Select
                  mode="multiple"
                  placeholder="Inserted are removed"
                  value={selectedItems}
                  onChange={handleChangeStudent}
                  style={{ width: "100%" }}
                >
                  {filteredOptions.map((item) => (
                    <Select.Option key={item.stuID} value={item.stuID}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <p className="form-label">Type</p>
              <div className="d-flex mb-2">
                <div className="form-group me-5">
                  <input
                    type="checkbox"
                    id="pickupCheckbox"
                    style={{ cursor: "pointer" }}
                    checked={pickupChecked}
                    onChange={() => handleCheckboxChange("pickup")}
                  />
                  <label
                    htmlFor="pickupCheckbox"
                    style={{ cursor: "pointer" }}
                    className="form-label ms-1"
                  >
                    Pickup
                  </label>
                </div>
                <div className="form-group me-5" style={{ cursor: "pointer" }}>
                  <input
                    type="checkbox"
                    id="visitCheckbox"
                    style={{ cursor: "pointer" }}
                    checked={visitChecked}
                    onChange={() => handleCheckboxChange("visit")}
                  />
                  <label
                    htmlFor="visitCheckbox"
                    style={{ cursor: "pointer" }}
                    className="form-label ms-1"
                  >
                    Visit
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="othersCheckbox"
                    style={{ cursor: "pointer" }}
                    checked={othersChecked}
                    onChange={() => handleCheckboxChange("others")}
                  />
                  <label
                    htmlFor="othersCheckbox"
                    style={{ cursor: "pointer" }}
                    className="form-label ms-1"
                  >
                    Others
                  </label>
                </div>
              </div>
              {visitChecked && (
                <>
                  <div className="row">
                    <div className="form-group mb-2">
                      <label className="form-label">Date</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="startdatetime"
                        value={formData.startdatetime} // Bind the value from state
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Visitor</label>
                    <input
                      type="text"
                      className="form-control"
                      name="visitorname"
                      value={formData.visitorname}
                      onChange={handleInputChange}
                      placeholder="Visitor Name"
                    />
                  </div>
                </>
              )}
              {(pickupChecked || othersChecked) && (
                <>
                  <div className="row">
                    <div className="form-group mb-2 col-md-6">
                      <label className="form-label">Pickup Date</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="startdatetime"
                        value={formData.startdatetime}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-2 col-md-6">
                      <label className="form-label">Drop Date</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="enddatetime"
                        value={formData.enddatetime}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Picker</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Picker Name"
                      name="visitorname"
                      value={formData.visitorname}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}
              <div className="form-group mb-3">
                <label className="form-label">Reason</label>
                <textarea
                  className="form-control"
                  placeholder="Enter purpose.."
                  name="reason"
                  value={formData.reason}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <button className="btn btn-primary mx-auto d-block">
                {submitBtnLoading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </Base>
  );
}
