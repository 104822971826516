
import React, { useState, useRef, useEffect } from 'react';
import CampusLogo from '../campuslogo.png';
import SigninSideImg from './signinsideimg.jpg';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL, BASE_SUPERID } from './Config';
// import './style.css';

export default function SignIn() {
    const navigate = useNavigate();

    const [otpDisplay, setOtpDisplay] = useState('none');
    const [userDetails, setUserDetails] = useState([]);
    const [signinDisplay, setSigninDisplay] = useState('block');
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [genOtp, setGenOtp] = useState('');
    const [otpMessage, setOtpMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [mobileNumberMessage, setMobileNumberMessage] = useState('');
    const [userName, setUserName] = useState('');
    const otpInputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const handleMobileNumber = (e) => {
        setMobileNumber(e.target.value);
        setMobileNumberMessage('');
    }

    const generateOTP = () => {
        console.log('fun call gen otp');
        return Math.floor(100000 + Math.random() * 900000).toString();
    };

    useEffect(() => {
        setGenOtp(generateOTP());
    }, []);

    const handleDetailsSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setMobileNumberMessage('');
        
        try {

            if (mobileNumber.length !== 10) {
                setLoading(false);
                setMobileNumberMessage('*Mobile number should be 10 digits.');
            }
            else {
                const response = await fetch(`${API_BASE_URL}GetParentDetails?superid=${BASE_SUPERID}&mobile=${mobileNumber}`);
                if (response.ok) {
                    const result = await response.json();
                    if (result.Status) {
                        sessionStorage.setItem('studentsData', JSON.stringify(result.ResultData));

                        setLoading(false);
                        setOtpDisplay('block');
                        setSigninDisplay('none');

                        const userDataToSession = {
                            Mobile: mobileNumber,
                            Name: userName,
                        };
                        console.log(userDataToSession, 'userdatatosessipon');
                        sessionStorage.setItem('userDataSession', JSON.stringify(userDataToSession));
                        saveUserDetailsToSessionStorage();

                        // try {
                        //     setGenOtp(generateOTP());
                        //     const messageData = {
                        //         "superid": 50018,
                        //         "tomobile": mobileNumber,
                        //         "sendername": "Parent",
                        //         "otp": genOtp,
                        //         "fromorg": "Birla"
                        //     }
                        //     const messageResponse = await fetch('https://notifyservisesrc.azurewebsites.net/notify/sendotpsms', {
                        //         method: 'POST',
                        //         headers: {
                        //             'Content-Type': 'application/json',
                        //         },
                        //         body: JSON.stringify(messageData),
                        //     });

                        //     if (messageResponse.ok) {
                        //         const messageResult = await messageResponse.json();
                        //         if (messageResult.status) {
                        //             saveUserDetailsToSessionStorage();

                        //             const userDataToSession = {
                        //                 Mobile: mobileNumber,
                        //                 Name: userName,
                        //             };
                        //             console.log(userDataToSession, 'userdatatosessipon')
                        //             sessionStorage.setItem('userDataSession', JSON.stringify(userDataToSession));

                        //             console.log(result.ResultData);
                        //             setLoading(false);
                        //             setOtpDisplay('block');
                        //             setSigninDisplay('none');
                        //         }
                        //         else {
                        //             setLoading(false);
                        //             setMobileNumberMessage('*Error sending OTP, try after sometime.');
                        //         }
                        //     } else {
                        //         setLoading(false);
                        //         setMobileNumberMessage('*Error sending OTP, try after sometime.');
                        //     }

                        // } catch {
                        //     setLoading(false);
                        //     setMobileNumberMessage('*Error sending OTP, try after sometime.');
                        // }
                    }
                    else {
                        setLoading(false);
                        setMobileNumberMessage('*Mobile Number not found.');
                    }
                }
                else {
                    setLoading(false);
                    setMobileNumberMessage('*Error while fetching, try after sometime.')
                }
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            setMobileNumberMessage('*Error while fetching, try after sometime.')
        }
    };

    const saveUserDetailsToSessionStorage = () => {
        const userDetails = {
            mobile: mobileNumber,
            otp: genOtp,
            sessionExpiration: Date.now() + 10 * 60 * 1000,
        };
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    };

    useEffect(() => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const storedUserDetails = JSON.parse(userDetailsString);
            setUserDetails(storedUserDetails);
        }
    }, []);

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        const enteredOtp = otp.join('').trim();
        console.log(userDetails.otp, 'sent otp');

        if (userDetails && enteredOtp === '123456') {
            // navigate('/parent-request');
            navigate('/dashboard');
        } else {
            setOtpError('Please enter valid OTP');
        }
    };

    const handleOtpChange = (index, value) => {
        if (!/^\d*$/.test(value)) {
            setOtpError('Please enter only numeric values');
            return;
        }

        const newOtp = [...otp];
        newOtp[index] = value;

        if (value && index < otp.length - 1) {
            otpInputRefs[index + 1].current.focus();
        }

        setOtp(newOtp);
        setOtpError('');
    };

    useEffect(() => {
        const handleBackspace = (e) => {
            if (e.key === 'Backspace') {
                const index = otp.findIndex(digit => !digit);
                if (index > 0) {
                    otpInputRefs[index - 1].current.focus();
                }
            }
        };

        window.addEventListener('keydown', handleBackspace);

        return () => {
            window.removeEventListener('keydown', handleBackspace);
        };
    }, [otp, otpInputRefs]);

    const handleResendOTP = async () => {
        setOtpMessage('*Sending OTP...')
        // Clear the OTP input fields
        setOtp(['', '', '', '', '', '']);
        setOtpError('');

        // Generate a new OTP
        const newOtp = generateOTP();
        userDetails.otp = newOtp
        // Update session storage with the new OTP
        const updatedUserDetails = { ...userDetails, otp: newOtp };
        sessionStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
        console.log(userDetails.otp, 'resend otp');
        const messageData = {
            "superid": 50018,
            "tomobile": userDetails.mobile,
            "sendername": "Parent",
            "otp": newOtp,
            "fromorg": "Birla"
        }
        const messageResponse = await fetch('https://notifyservisesrc.azurewebsites.net/notify/sendotpsms', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(messageData),
        });

        const messageResult = await messageResponse.json();
        if (messageResult.status) {
            setOtpMessage('*OTP sent successfully.')
        }
    };

    const handleCardChangeBtn = () => {
        setSigninDisplay('block');
        setOtpDisplay('none');
    }

    return (
        <div>
            <img src={CampusLogo} className='m-2' style={{ width: '12rem' }} alt='campus logo' />
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-7 me-5 d-none d-md-block">
                        <div className="">
                            <img src={SigninSideImg} className="card-img-top" alt="Image" />
                        </div>
                    </div>
                    <div className="col-md-4 md-ms-5">
                        <div className="card shadow">
                            <div className={`card-body m-3 d-${signinDisplay}`}>
                                <h4 className="card-title text-center mb-3">SigIn</h4>
                                <form onSubmit={handleDetailsSubmit}>
                                    <div className="form-group mb-3">
                                        <label className='form-label'><i className="fa-regular fa-user me-2"></i>User Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter user name" 
                                            onChange={(e) => setUserName(e.target.value)} 
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className='form-label'><i className="fa-solid fa-phone me-2"></i>Phone Number</label>
                                        <input
                                            type="number"
                                            className="form-control"

                                            placeholder="Enter phone number"
                                            onChange={handleMobileNumber}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary d-flex m-auto">{loading ? 'Submitting...' : 'Submit'}</button>
                                    <p className='text-danger'>{mobileNumberMessage}</p>
                                </form>
                                <p className='text-primary mt-5'>*OTP will send to entered Mobile Number.</p>
                            </div>

                            <div className={`card-body m-3 d-${otpDisplay}`}>
                                <form onSubmit={handleOTPSubmit}>
                                    <div className="p-2 text-center">
                                        <h4 className=''>Enter OTP</h4>
                                        <div>
                                            <span>A code has been sent to Mobile Number</span>
                                        </div>
                                        <div id="otp" className="inputs d-flex flex-row justify-content-center mt-5">
                                            {otp.map((digit, index) => (
                                                <input
                                                    key={index}
                                                    className="m-2 text-center form-control shadow"
                                                    type="text"
                                                    style={{ width: '2.5rem' }}
                                                    maxLength="1"
                                                    value={digit}
                                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                                    ref={otpInputRefs[index]}
                                                    required
                                                />
                                            ))}
                                        </div>
                                        {otpError && <p className='text-danger'>{otpError}</p>}
                                        {otpMessage && <p className='text-primary mt-1'>{otpMessage}</p>}
                                        <button type='submit' className='btn btn-primary mt-3 text-capitalize'>Submit</button>
                                    </div>
                                </form>
                                <button className='btn d-flex m-auto my-5 border text-capitalize text-warning fw-bold resendbtn' onClick={handleResendOTP}>Resend OTP<i className="fa-regular fa-share-from-square mt-1 ms-2"></i></button>
                                <p className='text-end form-label text-primary' style={{ cursor: 'pointer' }} onClick={handleCardChangeBtn}><i className="fa-solid fa-arrow-left-long me-1 mt-1"></i>Go Back to SignIn</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}