import React, { useEffect, useState } from "react";
import CampusLogo from "../campuslogo.png";
import "./parentrequest.css";
import { Link } from "react-router-dom";
import { Button, Popover } from "antd";

export default function Base({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [userDataSession, setUserDataSession] = useState("");

  useEffect(() => {
    const userDataSession = sessionStorage.getItem("userDataSession");
    setUserDataSession(JSON.parse(userDataSession));
  }, []);

  const content = (
    <div className="">
      <p className="text-primary">
        {userDataSession ? userDataSession.Name : ""}
      </p>
      <hr></hr>
      <Link to="/" className="text-danger">
        <p>
          SignOut<i className="fa-solid fa-arrow-right-from-bracket ms-1"></i>
        </p>
      </Link>
    </div>
  );

  // const openSidebar = () => {
  //     setSidebarOpen(true);
  // };

  // const closeSidebar = () => {
  //     setSidebarOpen(false);
  // };

  // const sidebarStyle = {
  //     display: sidebarOpen ? 'block' : 'none',
  //     width: '15rem',
  //     marginLeft: sidebarOpen ? '0%' : '15rem'
  // };

  const mainStyle = {
    marginLeft: sidebarOpen ? "0%" : "0%",
  };

  return (
    <div className="container-fluid">
        <div className="bg-light d-flex justify-content-between align-items-center">
            <div className="w3-container">
                <img src={CampusLogo} style={{ width: "12rem" }} alt="campuslogo" />
            </div>
            <h3 className="gradient-text">School Name Display's Here</h3>
            <div className="">
                <button className="btn btn-white" title="User Name">
                <Popover
                    placement="bottomRight"
                    content={content}
                    style={{ width: '60px' }}
                >
                    <Button>
                        <i className="fa-regular fa-circle-user text-dark fs-4"></i>
                    </Button>
                </Popover>
            </button>
            </div>
      </div>

      {/* <div className="w3-sidebar w3-bar-block w3-card w3-animate-left" style={sidebarStyle}>
                <button className="btn d-flex ms-auto border m-1" onClick={closeSidebar}> &times;</button>
                <Link to='/parent-request' className="w3-bar-item w3-button fs-6">
                    <i className="fa-regular fa-newspaper me-3"></i>Request
                </Link>
                <Link to='/apply-requests' className="w3-bar-item w3-button fs-6">
                    <i className="fa-solid fa-code-pull-request me-3"></i>Applied Requests
                </Link>
                <Link to='/request-reports' className="w3-bar-item w3-button fs-6">
                    <i className="fa-solid fa-chart-gantt me-3"></i>Request Reports
                </Link>
            </div> */}
      <div id="main" style={mainStyle}>
        {children}
      </div>
    </div>
  );
}
