
import React from "react";
import Base from "./Base";
import UserIcon from './usericon.png';

export default function StudentInfo() {
  return (
    <Base>
        {/* <div className="d-flex mt-3">
            <div className="tabset col-md-6 card shadow rounded border mb-3 mb-md-0">
                <div className="card-body">
                    <h5 className="text-center">Student Details</h5>
                    <section id="studentInfo" className="tab-panel">
                        <table className="table table-responsive">
                            <tbody>
                                <tr>
                                    <td>Admission Date</td>
                                    <td className="fw-semibold">18/03/2003</td>
                                    <td>Date Of Birth</td>
                                    <td className="fw-semibold">18/03/2012</td>
                                </tr>
                                <tr>
                                    <td>Religion</td>
                                    <td className="fw-semibold">Hindu</td>
                                    <td>Caste</td>
                                    <td className="fw-semibold">BC-D</td>
                                </tr>
                                <tr>
                                    <td>Adhar Number</td>
                                    <td className="fw-semibold">9898 9999 2222</td>
                                    <td>Mother Tongue</td>
                                    <td className="fw-semibold">Telugu</td>
                                </tr>
                                <tr>
                                    <td>Blood Group</td>
                                    <td className="fw-semibold">O +ve</td>
                                    <td>Previous School</td>
                                    <td className="fw-semibold">Mother Theresa School</td>
                                </tr>
                                <tr>
                                    <td>Identification Marks</td>
                                    <td className="fw-semibold" colSpan='8'>A Mole on Left Hand.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="border-bellow"></div>

                        <h6 className="my-1 text-center">Contact Details</h6>
                        <table className="table table-responsive">
                            <tbody>
                                <tr>
                                    <td>Mobile No</td>
                                    <td className="fw-semibold">9866986345</td>
                                    <td>Landline No</td>
                                    <td className="fw-semibold">040-456 789</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td className="fw-semibold" colSpan='8'>4-7-987/A2, Banjarahills, Road no 12, Hyderabad 500004</td>
                                </tr>
                                <tr>
                                    <td>Email Address</td>
                                    <td className="fw-semibold" colSpan='8'>XYZ@gmail.com</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="border-bellow"></div>

                        <h6 className="my-1 text-center">Parent Details</h6>
                        <table className="table table-responsive">
                            <tbody>
                                <tr>
                                    <td>Father's Name</td>
                                    <td className="fw-semibold">G.Ragahavendhra</td>
                                    <td>Mother's Name</td>
                                    <td className="fw-semibold">G.Mahalakshmi</td>
                                </tr>
                                <tr>
                                    <td>Father's Adhar</td>
                                    <td className="fw-semibold">N/A</td>
                                    <td>Mother's Adhar</td>
                                    <td className="fw-semibold">N/A</td>
                                </tr>
                                <tr>
                                    <td>Father's Occupation</td>
                                    <td className="fw-semibold">Govt.Employee</td>
                                    <td>Mother's Occupation</td>
                                    <td className="fw-semibold">House Wife</td>
                                </tr>
                                <tr>
                                    <td>Father's Qualification</td>
                                    <td className="fw-semibold">B.Tech</td>
                                    <td>Mother's Qualification</td>
                                    <td className="fw-semibold">MBA</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
            <div className="col-md-6 card shadow rounded border mb-3 mb-md-0">
                <div className="card-body">
                    <h5 className="text-center">Fee Details</h5>
                    <section>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>Fee Category</th>
                                    <th>Occurence</th>
                                    <th>Paid Amount</th>
                                    <th>Receipt</th>
                                    <th>Paid Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="fw-semibold">Application Form</td>
                                    <td>Only Once</td>
                                    <td>1000.00</td>
                                    <td>1579</td>
                                    <td>27/03/2023</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold">Registration Fee</td>
                                    <td>Only Once</td>
                                    <td>20000.00</td>
                                    <td>1579</td>
                                    <td>27/03/2023</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold">Boarding & Tution Fee</td>
                                    <td>Term-1</td>
                                    <td>60000.00</td>
                                    <td>1579</td>
                                    <td>27/03/2023</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold">Dhobi Charges</td>
                                    <td>Yearly</td>
                                    <td>9600.00</td>
                                    <td>1579</td>
                                    <td>27/03/2023</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold">Pocket Money</td>
                                    <td>Yearly</td>
                                    <td>10000.00</td>
                                    <td>1579</td>
                                    <td>27/03/2023</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold">Boarding & Tution Fee</td>
                                    <td>Term-2</td>
                                    <td>80000.00</td>
                                    <td>1579</td>
                                    <td>27/03/2023</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold">Boarding & Tution Fee</td>
                                    <td>Term-3</td>
                                    <td>80000.00</td>
                                    <td>1579</td>
                                    <td>27/03/2023</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
        </div> */}
            <div className="row mt-3">
                <div className="col-md-2">
                    <div className="card shadow rounded border mb-3 mb-md-0">
                        <div className="card-body">
                            <img src={UserIcon} className="d-flex m-auto" alt='user-icon' style={{ width: '5rem' }} />
                            <h5 className="text-center">Rama Krishna K</h5>
                            <div className="table-responsive">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Admission Number</td>
                                            <td className="fw-semibold">1987</td>
                                        </tr>
                                        <tr>
                                            <td>Badge</td>
                                            <td className="fw-semibold">476823</td>
                                        </tr>
                                        <tr>
                                            <td>Standard</td>
                                            <td className="fw-semibold">5th</td>
                                        </tr>
                                        <tr>
                                            <td>Section</td>
                                            <td className="fw-semibold">A</td>
                                        </tr>
                                        <tr>
                                            <td>Gender</td>
                                            <td className="fw-semibold">Male</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow rounded border mb-3 mb-md-0">
                        <div className="card-body">
                            <h5 className="text-center">Student Details</h5>
                            <div className="table-responsive">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Admission Date</td>
                                            <td className="fw-semibold">18/03/2003</td>
                                            <td>Date Of Birth</td>
                                            <td className="fw-semibold">18/03/2012</td>
                                        </tr>
                                        <tr>
                                            <td>Religion</td>
                                            <td className="fw-semibold">Hindu</td>
                                            <td>Caste</td>
                                            <td className="fw-semibold">BC-D</td>
                                        </tr>
                                        <tr>
                                            <td>Adhar Number</td>
                                            <td className="fw-semibold">9898 9999 2222</td>
                                            <td>Mother Tongue</td>
                                            <td className="fw-semibold">Telugu</td>
                                        </tr>
                                        <tr>
                                            <td>Blood Group</td>
                                            <td className="fw-semibold">O +ve</td>
                                            <td>Previous School</td>
                                            <td className="fw-semibold">Mother Theresa School</td>
                                        </tr>
                                        <tr>
                                            <td>Identification Marks</td>
                                            <td className="fw-semibold" colSpan='8'>A Mole on Left Hand.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="border-bellow"></div>

                            <h6 className="my-1 text-center">Contact Details</h6>
                            <div className="table-responsive">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Mobile No</td>
                                            <td className="fw-semibold">9866986345</td>
                                            <td>Landline No</td>
                                            <td className="fw-semibold">040-456 789</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td className="fw-semibold" colSpan='8'>4-7-987/A2, Banjarahills, Road no 12, Hyderabad 500004</td>
                                        </tr>
                                        <tr>
                                            <td>Email Address</td>
                                            <td className="fw-semibold" colSpan='8'>XYZ@gmail.com</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                                <div className="border-bellow"></div>

                            <h6 className="my-1 text-center">Parent Details</h6>
                            <div className="table-responsive">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Father's Name</td>
                                            <td className="fw-semibold">G.Ragahavendhra</td>
                                            <td>Mother's Name</td>
                                            <td className="fw-semibold">G.Mahalakshmi</td>
                                        </tr>
                                        <tr>
                                            <td>Father's Adhar</td>
                                            <td className="fw-semibold">N/A</td>
                                            <td>Mother's Adhar</td>
                                            <td className="fw-semibold">N/A</td>
                                        </tr>
                                        <tr>
                                            <td>Father's Occupation</td>
                                            <td className="fw-semibold">Govt.Employee</td>
                                            <td>Mother's Occupation</td>
                                            <td className="fw-semibold">House Wife</td>
                                        </tr>
                                        <tr>
                                            <td>Father's Qualification</td>
                                            <td className="fw-semibold">B.Tech</td>
                                            <td>Mother's Qualification</td>
                                            <td className="fw-semibold">MBA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card shadow rounded border mb-3 mb-md-0">
                        <div className="card-body">
                            <h5 className="text-center">Fee Details</h5>
                            <div className="table-responsive">
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th>Fee Category</th>
                                            <th>Occurence</th>
                                            <th>Paid Amount</th>
                                            <th>Receipt</th>
                                            <th>Paid Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="fw-semibold">Application Form</td>
                                            <td>Only Once</td>
                                            <td>1000.00</td>
                                            <td>1579</td>
                                            <td>27/03/2023</td>
                                            <td className='text-center'>
                                                <i className="fa-solid fa-file-arrow-down fs-4 text-warning" style={{ cursor: 'pointer' }} title='Download Receipt'></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-semibold">Registration Fee</td>
                                            <td>Only Once</td>
                                            <td>20000.00</td>
                                            <td>1579</td>
                                            <td>27/03/2023</td>
                                            <td className='text-center'>
                                                <i className="fa-solid fa-file-arrow-down fs-4 text-warning" style={{ cursor: 'pointer' }} title='Download Receipt'></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-semibold">Boarding & Tution Fee</td>
                                            <td>Term-1</td>
                                            <td>60000.00</td>
                                            <td>1579</td>
                                            <td>27/03/2023</td>
                                            <td className='text-center'>
                                                <i className="fa-solid fa-file-arrow-down fs-4 text-warning" style={{ cursor: 'pointer' }} title='Download Receipt'></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-semibold">Dhobi Charges</td>
                                            <td>Yearly</td>
                                            <td>9600.00</td>
                                            <td>1579</td>
                                            <td>27/03/2023</td>
                                            <td className='text-center'>
                                                <i className="fa-solid fa-file-arrow-down fs-4 text-warning" style={{ cursor: 'pointer' }} title='Download Receipt'></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-semibold">Pocket Money</td>
                                            <td>Yearly</td>
                                            <td>10000.00</td>
                                            <td>1579</td>
                                            <td>27/03/2023</td>
                                            <td className='text-center'>
                                                <i className="fa-solid fa-file-arrow-down fs-4 text-warning" style={{ cursor: 'pointer' }} title='Download Receipt'></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-semibold">Boarding & Tution Fee</td>
                                            <td>Term-2</td>
                                            <td>80000.00</td>
                                            <td>1579</td>
                                            <td>27/03/2023</td>
                                            <td className='text-center'>
                                                <i className="fa-solid fa-file-arrow-down fs-4 text-warning" style={{ cursor: 'pointer' }} title='Download Receipt'></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-semibold">Boarding & Tution Fee</td>
                                            <td>Term-3</td>
                                            <td>80000.00</td>
                                            <td>1579</td>
                                            <td>27/03/2023</td>
                                            <td className='text-center'>
                                                <i className="fa-solid fa-file-arrow-down fs-4 text-warning" style={{ cursor: 'pointer' }} title='Download Receipt'></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Base>
  );
}
